import { Html5Qrcode } from 'html5-qrcode';
import { useEffect, useRef } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5Qrcode.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    if (props.showTorchButtonIfSupported !== undefined) {
        config.showTorchButtonIfSupported = props.showTorchButtonIfSupported;
    }
    if (props.showZoomSliderIfSupported !== undefined) {
        config.showZoomSliderIfSupported = props.showZoomSliderIfSupported;
    }
    return config;
};

const Html5QrcodePlugin = (props) => {

    const qrCodeRef = useRef(null); // Reference to hold the Html5Qrcode instance

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                let rearCameraId = null;

                devices.forEach(device => {
                    if (device.kind === 'videoinput' && device.label.toLowerCase().indexOf('back') !== -1) {
                        rearCameraId = device.deviceId;
                    }
                });

                return rearCameraId;
            })
            .then(rearCameraId => {
                const config = createConfig(props);
                const verbose = props.verbose === true;

                if (!(props.qrCodeSuccessCallback)) {
                    throw "qrCodeSuccessCallback is required callback.";
                }

                qrCodeRef.current = new Html5Qrcode(qrcodeRegionId, verbose);
                const videoConstraints = rearCameraId ? { deviceId: { exact: rearCameraId } } : { facingMode: 'environment' };

                qrCodeRef.current.start(
                    videoConstraints, // Parametri della videocamera
                    config, // configurazione Html5Qrcode
                    props.qrCodeSuccessCallback,
                    props.qrCodeErrorCallback
                )
                    .catch(err => {
                        // Gestisci gli errori qui
                        console.error("Failed to start html5Qrcode. ", err);
                    });
            })
            .catch(error => {
                // Gestisci gli errori qui
                console.error("Failed to enumerate devices. ", error);
            });

        return () => {
            qrCodeRef.current.clear().catch(error => {
                console.error("Failed to clear html5Qrcode. ", error);
            });
        };
    }, []);

    return (
        <div id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;
