// @ts-check

import React, { useState } from 'react';
import './App.css';
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
import ResultContainerPlugin from './ResultContainerPlugin.jsx';
import reactLogo from "./download.png";

const App = (props) => {
    const [decodedResults, setDecodedResults] = useState([]);
    let lastdecoded = '';
    const onNewScanResult = (decodedText, decodedResult) => {
        if (lastdecoded != decodedText){
            lastdecoded = decodedText;
            window.location.replace("https://apps.tgroupspa.com/viaggi/esita_spedizione_libera/" + decodedText);
            setDecodedResults(prev => [...prev, decodedResult]);
        }
    };

    return (
        <div className="App">
            <section className="App-section">
                <img src={reactLogo}></img>
                <br />
                <br />
                <br />
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                    showTorchButtonIfSupported={true}
                    showZoomSliderIfSupported={true}
                    />
                <ResultContainerPlugin results={decodedResults} />
            </section>
        </div>
    );
};

export default App;
